import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LogoUpload } from '../models/logo-upload';
import { UploaderService } from '../services/uploader.service';
import { BaseComponent } from '../../shared/components/component-base/base.component';
import { takeUntil } from 'rxjs/operators';
import { logoSettings } from './logo-settings';

@Component({
    selector: 'sk-platform-branding',
    templateUrl: './platform-branding.component.html',
    styleUrls: ['./platform-branding.component.scss'],
})
export class PlatformBrandingComponent extends BaseComponent implements OnInit, AfterViewInit {
    refreshLogo = new EventEmitter();
    progress: number;
    isUploading: boolean;
    file: File;
    imageError: string;
    cardImageBase64: string;
    logoUpload: LogoUpload;
    isDimensionTooLarge = false;

    constructor(
        private toastrService: ToastrService,
        private translate: TranslateService,
        private uploader: UploaderService,
        private route: ActivatedRoute,
        private element: ElementRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.uploader.progressSource.pipe(takeUntil(this.destroy$)).subscribe((progress) => {
            this.progress = progress;
        });
    }

    ngAfterViewInit(): void {
        const fragment = this.route.snapshot.fragment;
        if (fragment) {
            this.element.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    onChange(file: File) {
        this.isDimensionTooLarge = false;
        const platformBrandingTranslationSectionKey = 'settings.account.PLATFORM_BRANDING';
        this.removeImage();

        if (file) {
            if (file.size > logoSettings.maxFileSize) {
                this.imageError = `${platformBrandingTranslationSectionKey}.FILE_SIZE_TOO_LARGE_ERROR`;
                return false;
            }

            if (!logoSettings.allowedTypes.some((x) => x === file.type)) {
                this.imageError = `${platformBrandingTranslationSectionKey}.FILE_NOT_ALLOWED_TYPE_ERROR`;
                return false;
            }

            this.file = file;

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event: any) => {
                this.loadImage(event.target.result)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((img) => {
                        if (img.width > logoSettings.maxDimension || img.height > logoSettings.maxDimension) {
                            this.imageError = `${platformBrandingTranslationSectionKey}.IMAGE_DIMENSIONS_TOO_LARGE_ERROR`;
                            this.isDimensionTooLarge = true;
                            return false;
                        }

                        this.cardImageBase64 = event.target.result;

                        this.logoUpload = new LogoUpload(
                            reader.result.toString().split(',')[1],
                            file.type,
                            file.name
                        );
                });
            }
        }
    }

    loadImage(fileReaderResult: any): Observable<HTMLImageElement> {
        return new Observable<HTMLImageElement>(observer => {
            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.onload = () => {
                observer.next(image);
                observer.complete();
            };
            image.onerror = err => {
                observer.error(err);
            };
            image.src = fileReaderResult;
        });
    }

    removeImage() {
        this.cardImageBase64 = null;
        this.progress = 0;
        this.file = undefined;
        this.imageError = null;
        this.isDimensionTooLarge = false;
    }

    onUpload() {
        this.progress = 0;
        this.isUploading = true;

        this.uploader
            .upload(this.file, this.logoUpload)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this.isUploading = false;
                    this.toastrService.success(this.translate.instant('COMMON.SUCCESS'));
                    this.removeImage();
                },
                (res) => {
                    if (res.error?.message) {
                        this.imageError = res.error.message;
                    } else {
                        this.toastrService.error(this.translate.instant('settings.common.error.request-issue'));
                    }
                    this.isUploading = false;
                }
            );
    }
}
