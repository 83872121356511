import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule, LayoutModule, SortingModule, StatusSpinnerModule, TasksModule } from '@skykick/core';
import { IntegrationsRoutingModule } from './integrations-routing.module';
import { DetailsComponent } from './connectwise/details/details.component';
import { IntegrationsComponent } from './integrations.component';
import { RouterModule } from '@angular/router';
import { CustomersComponent } from './connectwise/customers/customers.component';
import { TicketsComponent } from './connectwise/tickets/tickets.component';
import { SettingsComponent } from './connectwise/settings/settings.component';
import { HomeComponent } from './home/home.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs/breadcrumbs.component';
import { ConnectwiseComponent } from './connectwise/connectwise.component';
import { ConnectionWizardComponent } from './connectwise/connection-wizard/connection-wizard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerSettingsComponent } from './connectwise/customers/customer-settings/customer-settings.component';
import { CustomerAsidePanelComponent } from './connectwise/customers/customer-aside-panel/customer-aside-panel.component';
import { WizardStepsDirective } from './connectwise/core/directives/wizard-steps.directive';
import { AuthenticateComponent } from './connectwise/connection-wizard/authenticate/authenticate.component';
import { WizardStepsService } from './connectwise/core/services/wizard-steps.service';
import { MapProductsComponent } from './connectwise/connection-wizard/map-products/map-products.component';
import { SyncSettingsComponent } from './connectwise/connection-wizard/sync-settings/sync-settings.component';
import { MapCustomersComponent } from './connectwise/connection-wizard/map-customers/map-customers.component';
import { ConnectWisePartnerResolver } from './connectwise/core/resolvers/connectwise-partner.resolver';
import { CredentialsComponent } from './connectwise/settings/credentials/credentials.component';
import { ProductMappingComponent } from './connectwise/settings/product-mapping/product-mapping.component';
import { ProrationSettingsComponent } from './connectwise/settings/proration-settings/proration-settings.component';
import { MapTicketComponent } from './connectwise/connection-wizard/map-ticket/map-ticket.component';
import { InfoPanelComponent } from './connectwise/info/info-panel.component';

@NgModule({
    declarations: [
        IntegrationsComponent,
        HomeComponent,
        ConnectwiseComponent,
        DetailsComponent,
        CustomersComponent,
        TicketsComponent,
        SettingsComponent,
        BreadcrumbsComponent,
        ConnectionWizardComponent,
        CustomerSettingsComponent,
        CustomerAsidePanelComponent,
        WizardStepsDirective,
        AuthenticateComponent,
        MapProductsComponent,
        SyncSettingsComponent,
        MapCustomersComponent,
        CredentialsComponent,
        ProductMappingComponent,
        ProrationSettingsComponent,
        MapTicketComponent,
        InfoPanelComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        LayoutModule,
        IntegrationsRoutingModule,
        ReactiveFormsModule,
        NgbModule,
        StatusSpinnerModule,
        NgbTypeaheadModule,
        FormsModule,
        SortingModule,
        TasksModule,
        ButtonsModule,
        LayoutModule
    ],
    providers: [
        WizardStepsService,
        ConnectWisePartnerResolver
    ]
})
export class IntegrationsModule { }
