import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    ActivatedRoute,
    Router,
    UrlSegment,
} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import {
    forkJoin,
    merge,
    Observable,
    Subject,
    throwError,
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    finalize,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    ErrorModalService,
} from 'src/app/settings/shared/services/error-modal.service';
import {
    PublicUrlsProvider,
} from 'src/app/settings/shared/services/public.urls.provider';
import {
    RelativeUrlsProvider,
} from 'src/app/settings/shared/services/relative.urls.provider';

import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
    AppId,
    RouteResolverService,
} from '@skykick/core';
import {
    AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

import {
    AuthenticationType,
    PartnerPortalUserClaims,
} from '../../../models/partner-portal-user-claims';
import { ClaimsService } from '../../../services/claims.service';
import { UsersService } from '../../../services/users.service';
import { AddM365MemberRequest } from '../models/add-m365-member-request';
import { CmLicensePermissions } from '../models/cm-license-permissions';
import { EditMemberRequest } from '../models/edit-member-request';
import { InviteMemberRequest } from '../models/invite-member-request';
import {
    LicenseInfo,
    LicensePermission,
} from '../models/license.info';
import { M365UserSearchFilter } from '../models/m365-user-search-filter';
import { Member } from '../models/member';
import {
    MemberActionEffectService,
} from '../services/member-action-effect.service';
import { MemberEmailValidator } from '../services/member-email-validator';
import { MembersRoleProvider } from '../services/members.role.provider';
import { MembersService } from '../services/members.service';
import PermissionCheckbox from './models/permission.checkbox';
import RoleRadioButton from './models/role.radio.button';
import { UnlicensedPermissionResult } from '../../../models/unlicensed-permission-result';
import { environment } from 'src/environments/environment';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sk-app-add-edit-member-form',
    templateUrl: './add-edit-member-form.component.html',
    styleUrls: ['./add-edit-member-form.component.scss']
})
export class AddEditMemberFormComponent implements OnInit, OnDestroy {

    readonly cloudManagerLicenseId = 'd5f58783-bae6-4bb4-bd60-54c112aef5c6';
    readonly securityManagerLicenseId = '362b957a-b818-4a8a-a749-471564fb5797';
    editState: boolean;
    member: Member;
    partnerClaims: PartnerPortalUserClaims;
    updating = false;
    initialLoading: boolean;
    addMemberForm: FormGroup;
    addMemberEmailIsValid = true;
    addMemberEmailIsUnique = true;
    isRoleSelected = true;
    isProductSelected = true;
    executingMainAction = false;
    isResetPasswordUnavailable = true;
    developerPermissionDisabled = false;
    cloudBackupCheckbox: PermissionCheckbox;
    migrationsCheckbox: PermissionCheckbox;
    billingCheckbox: PermissionCheckbox;
    rolesRadioButtons: RoleRadioButton[];
    cloudBackupScope = 'Cloud_Backup';
    migrationsScope = 'Migrations';
    billingScope = 'Billing';
    private permissionsOrder = [
        "cbbd5b9c-153b-4268-8a17-67d2b35775d1",
        "11ca124a-0960-4625-a212-8091d16dd5a6",
        "f087b9be-bf13-4eb4-846a-08da50a229bf",
        "01a1ca5f-3e2a-4e74-846b-08da50a229bf",
        "5364aded-1546-4ce5-bc7f-9b932f3fa705"];
    click$ = new Subject<string>();
    private destroy$: Subject<void> = new Subject();

    loadingEmail = false;
    emailQueryIsEmpty = true;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private membersService: MembersService,
        private abstractUserProvider: AbstractUserProvider,
        private partnerService: ClaimsService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private errorModalService: ErrorModalService,
        private membersActionEffectHandler: MemberActionEffectService,
        private routeResolverService: RouteResolverService,
        private memberEmailValidator: MemberEmailValidator) {

        this.editState = this.isEditState();
        this.initialLoading = true;

        // Populating roles radio buttons with descriptions
        forkJoin(
            MembersRoleProvider.Roles.map(role => {
                return this.translateService.get(`settings.members.roles.${role.key.toLowerCase()}-desc`).pipe(
                    map(descriptionList => {
                        const descriptions: string[] = [];
                        for (const field of Object.keys(descriptionList)) {
                            descriptions.push(descriptionList[field]);
                        }
                        const rolesRadioButtons: RoleRadioButton = {
                            roleKey: role.key,
                            roleName: `${role.displayNameLocKey}-role`,
                            descriptionList: descriptions
                        };
                        return rolesRadioButtons;
                    }));
            })
        ).subscribe(res => this.rolesRadioButtons = res);

        this.translateService.get('settings.members.licenses.cloud-backup.cloud-backup-desc').pipe(
            map(descriptionList => {
                const descriptions: string[] = [];
                for (const field of Object.keys(descriptionList)) {
                    descriptions.push(descriptionList[field]);
                }
                const cloudBackupCheckbox: PermissionCheckbox = {
                    permissionName: 'settings.members.access.cloud-backup',
                    descriptionList: descriptions,
                    disabled: false
                };
                return cloudBackupCheckbox;
            })).subscribe(checkbox => this.cloudBackupCheckbox = checkbox);

        this.translateService.get('settings.members.licenses.migration.migration-desc').pipe(
            map(descriptionList => {
                const descriptions: string[] = [];
                for (const field of Object.keys(descriptionList)) {
                    descriptions.push(descriptionList[field]);
                }
                const migrationsCheckbox: PermissionCheckbox = {
                    permissionName: 'settings.members.access.migrations',
                    descriptionList: descriptions,
                    disabled: false
                };
                return migrationsCheckbox;
            })).subscribe(checkbox => this.migrationsCheckbox = checkbox);

        this.translateService.get('settings.members.licenses.billing.billing-desc').pipe(
            map(descriptionList => {
                const descriptions: string[] = [];
                for (const field of Object.keys(descriptionList)) {
                    descriptions.push(descriptionList[field]);
                }
                const billingCheckbox: PermissionCheckbox = {
                    permissionName: 'settings.members.access.billing',
                    descriptionList: descriptions,
                    disabled: false
                };
                return billingCheckbox;
            })).subscribe(checkbox => this.billingCheckbox = checkbox);
    }

    ngOnInit(): void {
        if (this.editState) {
            this.initEditState();
        }
        else {
            this.initAddState();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initEditState(): void {
        forkJoin([
            this.activeRoute.paramMap
                .pipe(
                    take(1),
                    map(params => params.get('memberId')),
                    switchMap(memberId => this.membersService.getMember(memberId)),
                    takeUntil(this.destroy$),
                ),
            this.partnerService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email)
                .pipe(
                    takeUntil(this.destroy$),
                )
        ]).pipe(
            tap(memberWithClaims => {
                const [member, partnerClaims] = memberWithClaims;
                this.partnerClaims = partnerClaims;
                this.member = member;
                this.member.licenses.forEach(license => {
                    // sort permissions
                    license.permissions.sort((a, b) => this.permissionsOrder.indexOf(a.id) - this.permissionsOrder.indexOf(b.id));
                    if (!license.enabled) {
                        // disabling permissions when license is disabled
                        license.permissions.forEach(permission => permission.enabled = false);
                    }
                });
                if (MembersRoleProvider.isAdmin(this.member.role)){
                    this.cloudBackupCheckbox.disabled = this.getUnlicensedPermission(this.cloudBackupScope).Enabled;
                    this.migrationsCheckbox.disabled = this.getUnlicensedPermission(this.migrationsScope).Enabled;
                    this.billingCheckbox.disabled = this.getUnlicensedPermission(this.billingScope).Enabled;
                    this.developerPermissionDisabled = this.member.isDeveloper;
                }

                this.isResetPasswordUnavailable =
                    partnerClaims === undefined ||
                    partnerClaims.authenticationType === AuthenticationType.O365Auth;

                this.initialLoading = false;
                this.changeDescriptionsOfCmPermissions();
            }),
            catchError(error => {
                return this.handleErrorDuringInitialLoading(error);
            })
        ).subscribe();
    }

    initAddState(): void {
        forkJoin([
            this.membersService.getMember(this.abstractUserProvider.getCurrentUser().userId)
                .pipe(
                    takeUntil(this.destroy$),
                ),
            this.partnerService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email)
                .pipe(
                    takeUntil(this.destroy$),
                )
        ]).pipe(
            tap(memberWithClaims => {
                const [member, partnerClaims] = memberWithClaims;
                this.partnerClaims = partnerClaims;
                this.initialLoading = false;

                member.licenses.forEach(license => {
                    license.enabled = false;
                    // sort permissions
                    license.permissions.sort((a, b) => this.permissionsOrder.indexOf(a.id) - this.permissionsOrder.indexOf(b.id));
                    license.permissions.forEach(permission => permission.enabled = permission.initialEnabledState = false);
                });

                member.unlicensedPermissions.forEach(permission => {
                    permission.Enabled = false;
                });

                this.member = {... this.getEmptyMember(), licenses: member.licenses, unlicensedPermissions: member.unlicensedPermissions};
                this.changeDescriptionsOfCmPermissions();
                this.addMemberEmailIsValid = true;
                this.addMemberEmailIsUnique = true;

                this.addMemberForm = this.formBuilder.group({
                    email: new FormControl(
                        '',
                        [Validators.email, Validators.required],
                        [this.memberEmailValidator.createValidator(this.membersService)])
                },
                    { updateOn: 'blur' });
            }),
            catchError(error => {
                return this.handleErrorDuringInitialLoading(error);
            })
        ).subscribe();
    }

    changeRole(role: string): void {
        if (!this.isOwnMember()) {
            this.member.role = role;

            if (MembersRoleProvider.isAdmin(role)){
                this.setAllNotCloudManagerPermissions(true, true);
            }
            else if (this.editState) {
                this.setAllNotCloudManagerPermissions(true, false);
            }
            else {
                this.setAllNotCloudManagerPermissions(false, false);
            }
    
            this.validateRole();
        }
    }

    toggleDeveloperStatus(): void {
        this.member.isDeveloper = !this.member.isDeveloper;
    }

    togglePermission(permission: LicensePermission): void {
        const value = !permission.enabled;
        this.member.licenses.forEach(l => {
            l.permissions.forEach(p => {
                if (p.id === permission.id){
                    p.enabled = value;
                }
            });
        });
    }

    getAvailableLicenses(license: LicenseInfo): number {
        return license.total - license.assigned;
    }

    isLicenseEnabled(license: LicenseInfo): boolean {
        return license?.enabled;
    }

    isLicenseAvailable(license: LicenseInfo): boolean {
        const availableLicensesCount = this.getAvailableLicenses(license);
        return availableLicensesCount > 0 || (availableLicensesCount === 0 && license.enabled);
    }

    isUnlicensedPermissionEnabled(permissionScopeName: string): boolean {
        return this.member.unlicensedPermissions.find(x => x.Scope === permissionScopeName).Enabled;
    }

    getUnlicensedPermission(permissionScopeName: string): UnlicensedPermissionResult {
        return this.member.unlicensedPermissions.find(x => x.Scope === permissionScopeName);
    }

    toggleUnlicensedPermission(permissionScopeName: string, value: boolean = null): void {
        const permission = this.member.unlicensedPermissions.find(x => x.Scope === permissionScopeName);
        permission.Enabled = value ?? !permission.Enabled;
    }

    setAllNotCloudManagerPermissions(checked: boolean, disabled: boolean): void {
        this.toggleUnlicensedPermission(this.cloudBackupScope, checked);
        this.toggleUnlicensedPermission(this.migrationsScope, checked);
        this.toggleUnlicensedPermission(this.billingScope, checked);
        this.member.isDeveloper = checked;
        this.cloudBackupCheckbox.disabled = disabled;
        this.migrationsCheckbox.disabled = disabled;
        this.billingCheckbox.disabled = disabled;
        this.developerPermissionDisabled = disabled;
    }

    validateForm(): void {
        this.validateRole();
        this.validateEmail();
        this.validateProduct();
    }

    validateRole(): void {
        this.isRoleSelected = this.member.role !== '';
    }

    validateEmail(): void {
        this.addMemberEmailIsUnique = !this.addMemberForm.controls.email.errors?.memberEmailExists;
        this.addMemberEmailIsValid = this.addMemberEmailIsUnique ?
            !this.addMemberForm.invalid : true;
    }

    validateProduct(): void {
        const hasUnlicensedPermissionsEnabled = this.member.unlicensedPermissions.find(x => x.Scope === this.cloudBackupScope).Enabled
          || this.member.unlicensedPermissions.find(x => x.Scope === this.migrationsScope).Enabled
          || this.member.unlicensedPermissions.find(x => x.Scope === this.billingScope).Enabled;

        const hasLicensesEnabled = this.member.licenses.length && this.member.licenses.some(x => x.enabled);

        this.isProductSelected = this.member.role === 'PartnerPortalAdmin'
          || this.member.role === 'PartnerPortal' && (hasUnlicensedPermissionsEnabled || hasLicensesEnabled);
    }

    isFormValid(): boolean {
        return this.isRoleSelected && this.addMemberEmailIsValid && this.addMemberEmailIsUnique && this.isProductSelected;
    }

    toggleLicense(license: LicenseInfo): void {
        if (this.editState) {
            if (!license.enabled) {
                // Disabling license
                license.permissions.forEach(permission => permission.enabled = false);
                license.assigned = license.assigned - 1;
            }
            else {
                // Enabling license
                license.permissions.forEach(permission => permission.enabled = permission.initialEnabledState);
                license.assigned = license.assigned + 1;
            }
        }
        else if (!license.enabled) {
            // Disabling license
            license.assigned = license.assigned - 1;
        }
        else {
            // Enabling license
            license.assigned = license.assigned + 1;
        }
    }

    getCmLicense() : LicenseInfo[] {
        return this.member.licenses.filter(x => x.id === this.cloudManagerLicenseId);
    }

    hasCmLicense() : boolean {
        return this.getCmLicense().length > 0;
    }

    getSmLicense() : LicenseInfo[] {
        return this.member.licenses.filter(x => x.id === this.securityManagerLicenseId);
    }

    hasSmLicense() : boolean {
        return this.getSmLicense().length > 0;
    }

    hasNoLicenses() : boolean {
        return !this.hasCmLicense() && !this.hasSmLicense();
    }

    resetEmailValidation(): void {
        this.addMemberEmailIsValid = true;
        this.addMemberEmailIsUnique = true;
    }

    getDevPortalPageUrl(): string {
        return PublicUrlsProvider.DevPortalPageUrl;
    }

    getTermsPageUrl(): string {
        return PublicUrlsProvider.TermsPageUrl;
    }

    getSmCustomerSupportPageUrl(): string {
        return PublicUrlsProvider.SmCustomerSupportPageUrl;
    }

    getAccountSettingsPageUrl(): string {
        return RelativeUrlsProvider.ManageAccountPageUrl;
    }

    getAuthTypeLocKey(): string {
        let authTypeLocKey = 'settings.common.authentication.skykick';
        if (this.isM365Authentication()) {
            authTypeLocKey = 'settings.common.authentication.m365';
        }
        else if (this.partnerClaims.isMFAEnabled) {
            authTypeLocKey = 'settings.common.authentication.skykick-mfa';
        }
        return authTypeLocKey;
    }

    isM365Authentication(): boolean {
        return this.partnerClaims.authenticationType === AuthenticationType.O365Auth;
    }

    resetPassword(member: Member): void {
        this.membersActionEffectHandler.resetPassword(
            this.membersService.resetPassword(member.username),
            member
        ).subscribe();
    }

    deactivateAccount(member: Member): void {
        this.membersActionEffectHandler.deactivate(
            this.membersService.deactivateMember(member.id),
            member,
            () => this.goToParentPage(member.id)
        ).subscribe();
    }

    buySubscription(product: string): void {
        window.location.href = this.routeResolverService.generateRatRoute(AppId.Purchase, `/overview/${product}`);
    }

    cancel(): void {
        this.goToParentPage();
    }

    addM365Member(): void {
        this.validateForm();

        if (!this.isFormValid() || this.addMemberForm.pending) {
            return;
        }
        else {
            this.executingMainAction = true;
            const addMemberRequest: AddM365MemberRequest = {
                email: this.addMemberForm.get('email').value,
                isAdmin: this.isAdminMember(),
                isDeveloper: this.member.isDeveloper,
                licenses: this.member.licenses.filter(license => this.isSecurityManagerLicensingFeatureActive() || license.id != this.securityManagerLicenseId),
                unlicensedPermissions: this.member.unlicensedPermissions,
            };

            this.membersService.addM365Member(addMemberRequest)
                .pipe(
                    tap(result => {
                        if (result) {
                            this.translateService.get('settings.members.actions.add-success').pipe(
                                tap((successText: string) => this.toastrService.success(successText))
                            ).subscribe();
                            this.goToParentPage(result.UserId);
                        }
                        else {
                            throw new Error('Unsuccessful response when adding member');
                        }
                    }),
                    catchError(error => {
                        this.translateService.get('settings.members.actions.add-failed').pipe(
                            tap((errorText: string) => this.toastrService.error(errorText))
                        ).subscribe();
                        return throwError(error);
                    })
                ).subscribe(
                    () => { },
                    () => this.executingMainAction = false
                );
        }
    }

    sendInvite(): void {
        this.validateForm();

        if (!this.isFormValid() || this.addMemberForm.pending) {
            return;
        }
        else {
            this.executingMainAction = true;
            const inviteMemberRequest: InviteMemberRequest = {
                email: this.addMemberForm.get('email').value,
                isAdmin: this.isAdminMember(),
                isDeveloper: this.member.isDeveloper,
                licenses: this.member.licenses.filter(license => this.isSecurityManagerLicensingFeatureActive() || license.id != this.securityManagerLicenseId),
                unlicensedPermissions: this.member.unlicensedPermissions,
            };
            this.membersService.inviteMember(inviteMemberRequest).pipe(
                tap(result => {
                    if (result) {
                        this.translateService.get('settings.members.actions.add-success').pipe(
                            tap((successText: string) => this.toastrService.success(successText))
                        ).subscribe();
                        this.goToParentPage(result.UserId);
                    }
                    else {
                        throw new Error('Unsuccessful response when adding member');
                    }
                }),
                catchError(error => {
                    return this.handleErrorDuringInitialLoading(error);
                })
            ).subscribe(
                () => { },
                () => this.executingMainAction = false
            );
        }
    }

    searchEmail = (searchTerm$: Observable<string>) => {
        return merge(this.click$, searchTerm$.pipe(debounceTime(200)))
            .pipe(
                distinctUntilChanged(),
                tap(term => {
                    this.loadingEmail = true;
                    term === '' ? this.emailQueryIsEmpty = true : this.emailQueryIsEmpty = false;
                }),
                switchMap(startsWith => this.membersService.getM365Users(new M365UserSearchFilter({ searchTerm: startsWith }))
                    .pipe(
                        map(user => user.map(x => x.userPrincipalName)),
                        catchError(error => {
                            this.translateService.get('settings.members.form.search-email-failed').pipe(
                                tap((errorText: string) => this.toastrService.error(errorText))
                            ).subscribe();
                            return throwError(error);
                        }),
                        finalize(() => this.loadingEmail = false))
                ),
                takeUntil(this.destroy$)
            );
    }

    searchFormatter(m365User: string): string {
        return m365User;
    }

    selectItem(event: NgbTypeaheadSelectItemEvent): void {
        this.addMemberEmailIsValid = true;
        this.emailQueryIsEmpty = false;
    }

    updateMember(): void {
        this.validateProduct();
        if (!this.isProductSelected)
          return;

        this.updating = true;

        const editMemberRequest: EditMemberRequest = {
            contactId: this.member.id,
            email: this.member.email,
            firstName: this.member.firstName,
            lastName: this.member.lastName,
            isAdmin: this.isAdminMember(),
            isDeveloper: this.member.isDeveloper,
            licenses: this.member.licenses.filter(license => this.isSecurityManagerLicensingFeatureActive() || license.id != this.securityManagerLicenseId),
            unlicensedPermissions: this.member.unlicensedPermissions,
        };

        this.membersService.editMember(editMemberRequest).pipe(
            tap(result => {
                if (result) {
                    this.translateService.get('settings.members.actions.edit-success').pipe(
                        tap((successText: string) => this.toastrService.success(successText))
                    ).subscribe();
                }
                else {
                    throw new Error('Unsuccessful response when editing member');
                }
            }),
            catchError(error => {
                this.translateService.get('settings.members.actions.edit-failed').pipe(
                    tap((errorText: string) => this.toastrService.error(errorText))
                ).subscribe();
                return throwError(error);
            })
        ).subscribe(
            () => this.updating = false,
            () => this.updating = false
        );
    }

    private handleErrorDuringInitialLoading(error: any): Observable<never> {
        this.initialLoading = false;
        this.errorModalService.openErrorModal();
        return throwError(error);
    }

    private changeDescriptionsOfCmPermissions(): void {
        const consoleDescLocKey = 'settings.members.licenses.cm.console-desc';
        const workbenchDescLocKey = 'settings.members.licenses.cm.workbench-desc';
        const deviceConnectionsDescLocKey = 'settings.members.licenses.cm.device-connect-desc';
        const commandAdminDescLocKey = 'settings.members.licenses.cm.command-admin-desc';

        this.translateService.get([consoleDescLocKey, workbenchDescLocKey, deviceConnectionsDescLocKey, commandAdminDescLocKey])
            .subscribe(translations => {
                this.member.licenses.forEach(license => {
                    license.permissions.forEach(permission => {
                        switch (permission.name) {
                            case CmLicensePermissions.Console:
                                permission.description = translations[consoleDescLocKey];
                                break;
                            case CmLicensePermissions.Workbench:
                                permission.description = translations[workbenchDescLocKey];
                                break;
                            case CmLicensePermissions.DeviceConnections:
                                permission.description = translations[deviceConnectionsDescLocKey];
                                break;
                            case CmLicensePermissions.CommandAdmin:
                                permission.description = translations[commandAdminDescLocKey];
                                break;
                        }
                    });
                });
            });
    }

    private getEmptyMember(): Member {
        return {
            id: '',
            username: '',
            firstName: '',
            lastName: '',
            fullName: '',
            email: '',
            role: '',
            status: '',
            access: [],
            isUsernameMapped: false,
            permissionScopes: [],
            unlicensedPermissions: [],
            isDeveloper: false,
            licenses: []
        };
    }

    private goToParentPage(id: string = undefined): void {
        if (this.isEditState()) {
            this.router.navigateByUrl('settings/users/members', { state: { refreshMemberId: id } });
        }
        else {
            this.router.navigateByUrl('settings/users/members', { state: { addMemberId: id } });
        }
    }

    private isEditState(): boolean {
        const segments = this.getUrlSegments();
        return segments[segments.length - 1].path === 'edit';
    }

    isOwnMember(): boolean {
        return this.abstractUserProvider.getCurrentUser().email.toLowerCase() === this.member.email.toLowerCase();
    }

    private isAdminMember(): boolean {
        return MembersRoleProvider.isAdmin(this.member.role);
    }

    private getUrlSegments(): UrlSegment[] {
        const tree = this.router.parseUrl(this.router.url);
        return tree.root.children.primary.segments;
    }

    onEmailSearchClear() {
        this.addMemberForm.controls.email.setValue('');
        this.emailQueryIsEmpty = true;
    }

    isSubmitDisabled() {
        return this.executingMainAction || this.addMemberForm.controls.email.pending ? true : null;
    }

    isSecurityManagerLicensingFeatureActive(): boolean {
        return environment.securityManagerLicensingFeatureActive;
    }
}
