/**
 * @summary Error codes that come to us from the backend, and we want to handle them.
 */
export enum ApiErrorCode {
  AzureCosmosFailure = 'azure_cosmos_failure',
  UserAliasShouldBeNotTheSameAsUserName = 'user_alias_not_same_as_username',
  UserAliasAlreadyExistsForTheUser = "user_alias_already_exists_for_the_user",
  CrmContactNotFound = "crm_contact_not_found",
  KenticoUserNotFound = "kentico_user_not_found",
  OrderNotFound = "order_not_found",
  CrmResellerNotFound = "crm_reseller_not_found",
}
