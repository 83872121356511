<div class="modal-header bg-white pb-0">
    <div class="d-flex flex-column">
        <div class="d-flex align-items-center ">
            <h3 class="modal-title font-normal" translate="settings.O365.O365LOGIN_MODAL_TITLE"></h3>
        </div>
    </div>

    <button type="button" class="close" (click)="activeModal.dismiss()" id="closeBtn">
        <i class="material-icons md-22">close</i>
    </button>
</div>

<div class="d-flex flex-grow-1 overflow-auto">
    <!-- Column Left -->
    <div class="d-flex">
        <div class="sk-panel-left sk-panel-secondary panel-xs-2">
            <div class="sk-panel-body">
                <div class="sk-panel-nav">
                    <ul class="nav sk-nav-vert flex-column">
                        <li *ngFor="let item of authorizationSteps; let i = index" class="nav-item">
                            <a id="wizardLink{{i}}" class="nav-link d-flex align-items-center px-150" [class.active]="item.active" [class.disabled]="true">
                                <i class="material-icons md-22 mr-50 icon-light d-flex" [class.icon-success]="item.complete">check_circle</i>
                                <span class="text-truncate" translate="{{'settings.O365.' + item.name}}"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Column Right -->
    <div class="d-flex flex-column flex-grow-1">

        <ng-container [ngSwitch]="authorizationStep.name">
            <ng-container *ngSwitchCase="'SETUP_AAD_ACCESS'">
                <div class="modal-body">
                    <sk-text-spinner *ngIf="isLoading" message="{{'COMMON.LOADING' | translate}}"></sk-text-spinner>

                    <ng-container *ngIf="!isLoading">
                        <h3 translate="settings.O365.AZURE_ACTIVE_DIRECTORY_ACCESS" class="m-b-2"></h3>
                        <form [formGroup]="grantAccessForm" novalidate>
                            <p class="mt-200" [innerHTML]="'settings.O365.ALLOW_ACCESS_TO_AZURE_ACTIVE_DIRECTORY_USERS' | translate"></p>
    
                            <fieldset class="form-group">
                                <div class="custom-control custom-checkbox sk-checkbox d-flex">
                                    <input type="checkbox" id="mfaCheck" class="custom-control-input" formControlName="mfaCheck">
                                    <label for="mfaCheck" class="custom-control-label font-medium" translate="settings.O365.MFA_CERTIFICATION_CHECKBOX"></label>
                                </div>
                            </fieldset>
    
                            <p class="mt-200" [innerHTML]="'settings.O365.ALLOW_ACCESS_TO_AZURE_ACTIVE_DIRECTORY_GROUPS' | translate"></p>
    
                            <fieldset class="form-group">
                                <div class="custom-control custom-checkbox sk-checkbox d-flex">
                                    <input type="checkbox" id="groupAccess" class="custom-control-input" formControlName="groupAccess">
                                    <label for="groupAccess" class="custom-control-label font-medium" translate="settings.O365.AAD_GROUP_SYNC_CHECKBOX"></label>
                                </div>
                            </fieldset>
                        </form>    
                    </ng-container>
                </div>
                <div class="modal-footer justify-content-start">
                    <button id="submitSETUP_AAD_ACCESS" (click)="submitSETUP_AAD_ACCESS()" [disabled]="grantAccessForm.invalid" [class.active]="isLoading"
                        class="btn btn-primary sk-has-spinner sk-abs-spinner">
                        <div class="sk-btn-spinner">
                            <div class="sk-spin-loader loader-dark"></div>
                        </div>
                        <span class="sk-loader-label" translate="settings.O365.NEXT_STEP"></span>
                    </button>
                    <button id="cancelBtn1" type="button" class="btn btn-secondary" (click)="activeModal.dismiss()"
                        translate="COMMON.CANCEL"></button>
                </div>    

            </ng-container>

            <ng-container *ngSwitchCase="'GRANT_AAD_ACCESS'">
                <div class="modal-body">
                    <sk-text-spinner *ngIf="isLoading" message="{{'COMMON.LOADING' | translate}}"></sk-text-spinner>

                    <ng-container *ngIf="!isLoading">
                        <h3 translate="settings.O365.AZURE_ACTIVE_DIRECTORY_ACCESS"></h3>
                        <p class="mt-200" translate="settings.O365.M365_ADMIN"></p>
                        <button type="button" class="btn btn-link p-0" (click)="copyAuthUriToClipboard()">{{ 'settings.O365.M365_COPY_AND_SHARE_LINK' | translate }}</button>
                    </ng-container>
                </div>
                <div class="modal-footer justify-content-start">
                    <button id="submitGRANT_AAD_ACCESS" (click)="submitGRANT_AAD_ACCESS()" [disabled]="isLoading" [class.active]="isLoading"
                        class="btn btn-primary sk-has-spinner sk-abs-spinner">
                        <div class="sk-btn-spinner">
                            <div class="sk-spin-loader loader-dark"></div>
                        </div>
                        <span class="sk-loader-label" translate="settings.O365.NEXT_STEP"></span>
                    </button>
                    <button id="cancelBtn2" type="button" class="btn btn-secondary" (click)="activeModal.dismiss()"
                        translate="COMMON.CANCEL"></button>
                </div>            
            </ng-container>

            <ng-container *ngSwitchCase="'ENABLE_O365_LOGIN'">
                <div class="modal-body">
                    <sk-text-spinner *ngIf="isLoading" message="{{'COMMON.LOADING' | translate}}"></sk-text-spinner>

                    <ng-container *ngIf="!isLoading">
                        <h3 translate="settings.O365.ENABLE_O365_LOGIN"></h3>
                        <p class="mt-100" translate="settings.O365.ENABLE_O365_DESC" id="O365-ENABLE-SUB-TEXT"></p>
                        
                        <div *ngIf="!mismatchedUsers.length" id="O365-NO-MISMATCH-SUCCESS">
                            <p translate="settings.O365.MATCH_USERNAMES_SUCCESS" id="O365-NO-MISMATCH-SUCCESS-SUB-TEXT"></p>
                        </div>

                        <div *ngIf="mismatchedUsers.length" id="O365-MISMATCH-SCREEN">

                            <ngb-alert class="d-flex mb-100" type="warning" [dismissible]="false">
                                <i class="material-icons md-22">error_outline</i>
                                <div class="ml-100">
                                    <span class="sk-alert-text" [innerHTML]="'settings.O365.MATCH_USERNAMES_WARNING' | translate"></span>
                                </div>
                            </ngb-alert>
                            
                            <form [formGroup]="misMatchForm" novalidate>
                                <ng-container formArrayName="mismatchedUsers">
                                    <div class="form-group">
                                        <table class="table table-hover sk-standard-wrap-table">
                                            <thead class="thead-white thead-sm thead-border-none">
                                                <tr>
                                                    <th translate="settings.O365.MISMATCHED_USERNAMES"></th>
                                                    <th translate="settings.O365.OFFICE_365_CREDENTIALS"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr sk-no-result has-result="mismatchedUsers.length" id="O365-MISMATCH-NO-ROWS"></tr>
                                                <tr *ngFor="let user of mismatchedUsers; let index = index">
                                                    <ng-container [formGroupName]="index">

                                                        <td class="py-150">
                                                            <input disabled type="text" id="O365-MISMATCH-SKUSERNAME-{{index}}" class="form-control" 
                                                                formControlName="SKUserName" placeholder="{{user.username}}" />
                                                        </td>
                                                        <td class="py-150">
                                                            <div [ngClass]="{'has-warning': (user.o365username == null || user.o365username === '')}" 
                                                                id="O365-MISMATCH-O365USERNAME-WARNING-{{index}}">
                                                                <div class="sk-input-group">
                                                                    <input id="typeaheadConnectWiseProduct{{index}}" type="text" class="form-control" 
                                                                    formControlName="O365LoginName"
                                                                    [ngbTypeahead]="index | applyPure : searchType"
                                                                    [editable]="false"
                                                                    (selectItem)="typeaheadOnSelect($event)"
                                                                    #instance="ngbTypeahead" 
                                                                    placeholder="{{'settings.O365.SEARCH_PLACEHOLDER' | translate}}" />
                                                                    <div *ngIf="userMismatchMappingFormArray.controls[index].get('isLoading').value" class="sk-spin-loader loader-sm"></div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                            </form>

                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer justify-content-start">
                    <button id="submitENABLE_O365_LOGIN" (click)="submitENABLE_O365_LOGIN()" [disabled]="false" [class.active]="isLoading"
                        class="btn btn-primary sk-has-spinner sk-abs-spinner">
                        <div class="sk-btn-spinner">
                            <div class="sk-spin-loader loader-dark"></div>
                        </div>
                        <span class="sk-loader-label" translate="settings.O365.ENABLE"></span>
                    </button>
                    <button id="cancelBtn3" type="button" class="btn btn-secondary" (click)="activeModal.dismiss()"
                        translate="COMMON.CANCEL"></button>
                </div>            
            </ng-container>
        </ng-container>

    </div>

</div>
