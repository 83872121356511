import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

// Can be combined with HasPermissionGuard into a single guard
@Injectable({
    providedIn: 'root'
})
export class ProductAccessGuard implements CanActivate {

    constructor(
        private abstractUserProvider: AbstractUserProvider
    ) { }

    canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): boolean {
        const user = this.abstractUserProvider.getCurrentUser();
        return user.hasPermission('Migrations_Full_Access') || user.hasPermission('Cloud_Backup_Full_Access');
    }
}