<div id="migrations_card" class="card h-100">
    <div class="card-header sk-card-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <i class="material-icons md-24 icon-primary mr-100">cloud_upload</i>
            <h4 translate>NAV.MIGRATIONS</h4>
        </div>
        <a id="migrations_card_arrow_button" class="btn sk-btn-icon sk-btn-icon pr-0" [class]="canAccess() ? '' : 'disabled'" href="https://migrate.skykick.com/partner/migrations/monitor#/">
            <i class="material-icons md-20 icon-muted">keyboard_arrow_right</i>
        </a>
    </div>
    <div class="card-body">
        <div *ngIf="gettingCounts && gettingAlerts;">
            <div class="d-flex align-items-center flex-column">
                <div class="sk-spin-loader" ng-class="size"></div>
            </div>
        </div>
        <div *ngIf="!gettingCounts && !gettingAlerts;">
            <div *ngIf="showBlurb" [@animateIn]>
                <p translate>home.cards.migrations_body_default</p>
                <a id="migrations_getting_started_link" href="https://support.skykick.com/hc/en-us/restricted?return_to=https%3A%2F%2Fsupport.skykick.com%2Fhc%2Fen-us%2Farticles%2F227775467-Migration-Getting-Started-Guide"
                    class="mr-3" translate>home.cards.links.getting_started_guide</a>
                <a id="choose_migration_link" href="https://support.skykick.com/hc/en-us/restricted?return_to=https%3A%2F%2Fsupport.skykick.com%2Fhc%2Fen-us%2Farticles%2F207431407-Choosing-a-Migration-Project-Type"
                    translate>home.cards.links.choosing_migration_type</a>
            </div>
            <div class="row">
                <div *ngIf="!showBlurb" [@animateIn] class="col-md-6">
                    <h1 class="display-4">{{migrationsInProgress}}</h1>
                    <a class="link-primary" (click)="onMigrationsInProgressClick()" translate>home.cards.links.in_progress</a>
                </div>
                <div *ngIf="!showBlurb" [@animateIn] class="col-md-6">
                    <h1 class="display-4">{{migrationsSaved}}</h1>
                    <a class="link-primary" (click)="onSavedMigrationsClick()" translate>home.cards.links.saved</a>
                </div>
            </div>
        </div>
    </div>
    <div id="sk-migration-critical-alert-link" role="button" class="d-flex px-100 py-50 bg-red-100 align-items-center justify-content-between"
    (click)="onCritAlertsClick()"
    *ngIf="!gettingCounts && !gettingAlerts && formattedCritialAlertCount != '0'" [@expandIn]>
          <div class="d-flex align-items-center">
            <i class="material-icons md-22 icon-danger pr-50">error</i>
            <div class="text-dark font-weight-medium" translate>home.cards.critical_notifications</div>
          </div>
          <span id="sk-migration-critical-alert-counts" class="badge badge-pill">{{formattedCritialAlertCount}}</span>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <button *ngIf="canAccess()" id="migrations_card_button" type="button" class="btn btn-secondary-outline mr-100 text-truncate" (click)="goToMigrations()" translate>home.cards.buttons.go_to_migrations</button>
        </div>
        <div *ngIf="!canAccess()" class="d-flex align-items-center mt-50 mb-50">
            <i class="material-icons md-20 icon-muted mr-50">lock</i>
            <div class="text-muted" translate>home.cards.migrations_permission_needed</div>
        </div>
    </div>
</div>
