<!-- PAGE HEADER -->
<div class="sk-page-header --header-light">
    <!-- NAVBAR PRIMARY -->
    <div class="sk-navbar-primary" [class.single-navbar]="!showTabs()">
        <!-- JUSTIFY LEFT -->
        <div class="d-flex align-items-center min-w-0">
            <!-- min-width for flex content -->
            <div class="d-flex min-w-0">
                <div class="min-w-0">
                    <ol class="breadcrumb sk-breadcrumb sk-breadcrumb-lg m-0 min-w-0 flex-nowrap">
                        <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
                            <li class="breadcrumb-item text-truncate" [class.active]="last">
                                <div [ngSwitch]="last" class="d-block text-truncate">
                                    <a *ngSwitchCase="false" [routerLink]="breadcrumb.data.url">{{ breadcrumb.title | translate }}</a>
                                    <ng-container *ngSwitchCase="true">{{ breadcrumb.title | translate }}</ng-container>
                                </div>
                            </li>
                            <li *ngIf="!last" class="breadcrumb-item arrow">
                                <i class="material-icons p-0">keyboard_arrow_right</i>
                            </li>
                        </ng-container>
                    </ol>
                </div>
            </div>
        </div>
    </div>

    <!-- NAVBAR SECONDARY -->
    <div *ngIf="showTabs()" class="sk-navbar-secondary">
        <!-- Navbar Option -->
        <ul class="nav sk-tabs-lg d-flex flex-nowrap text-truncate">
            <li class="nav-item">
                <a routerLink="members" routerLinkActive="active" class="nav-link">
                    {{ 'settings.users.members' | translate }}</a>
            </li>
            <li class="nav-item" *ngIf="showCustomersTab()">
                <a routerLink="customers" routerLinkActive="active" class="nav-link">
                    {{ 'settings.users.customers' | translate }}</a>
            </li>
        </ul>
        <div class="d-flex">
            <button sk-icon-button icon="info_outline" skUniversalTaskButton [taskName]="usersComponentContext.infoButtonTaskType" ngbTooltip="Info" placement="bottom">
            </button>
        </div>
    </div>
</div>
<!-- / END PAGE HEADER -->

<router-outlet></router-outlet>