<div class="card mt-200" id="uploadImage">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <h4>{{ 'settings.account.PLATFORM_BRANDING.CARD_TITLE' | translate }}</h4>
    </div>
    <div class="card-block">
        <p>{{ 'settings.account.PLATFORM_BRANDING.CARD_DESCRIPTION' | translate }}</p>
        <div class="row">
            <div class="col-7">
                <div class="form-group">
                    <label translate="settings.account.PLATFORM_BRANDING.UPLOAD_NEW_COMPANY_LOGO"></label>
                    <div class="custom-file">
                        <input #fileInput type="file" class="custom-file-input" id="selectedFile"
                            accept=".png,.jpg,.jpeg,.gif"
                            (change)="onChange(fileInput.files[0])" />
                        <label class="custom-file-label" for="selectedFile">
                            <div class="custom-file-name text-truncate">{{ file?.name ||  ('settings.account.CHOOSE_A_FILE' | translate) }}</div>
                        </label>
                    </div>
                    <small id="logoHelp" class="sk-form-text text-muted">{{'settings.account.PLATFORM_BRANDING.RECOMMENDED_LOGO_SIZE' | translate}}</small>
                </div>
                <p><ngb-progressbar type="success" textType="white" [value]="progress" [showValue]="true"></ngb-progressbar></p>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <label>{{ 'settings.account.PLATFORM_BRANDING.PREVIEW' | translate }}</label>
                    <div class="sk-partner-brand">
                        <img *ngIf="!cardImageBase64" class="partner-logo" src="/assets/images/360x88.gif" />
                        <img *ngIf="cardImageBase64" class="partner-logo" [src]="cardImageBase64" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-7">
            <div *ngIf="imageError" class="form-group has-danger">
              <small class="form-control-feedback">{{imageError | translate}}</small>
            </div>

            <button type="button" id="uploadImageBtn" class="btn btn-primary mr-100" (click)="onUpload()"
              [disabled]="!file?.name || isUploading || isDimensionTooLarge">{{ 'COMMON.UPLOAD' | translate }}</button>
            <button *ngIf="cardImageBase64" type="button" id="removeImageBtn" class="btn btn-outline-secondary"
              (click)="removeImage()" [disabled]="isUploading">{{ 'COMMON.CANCEL' | translate }}</button>
          </div>
        </div>
    </div>
</div>
