import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
} from '@angular/core';

import {
  CardsResourcesService,
} from 'src/app/home/services/cards-resources.service';
import { CardsUtilities } from 'src/app/home/utilities/cards-utilities';

import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import {
  AppId,
  NotificationsControlService,
  ProductTypes,
  RouteResolverService,
  SeverityTypes,
} from '@skykick/core';

@Component({
  selector: 'sk-migrations-card',
  templateUrl: './migrations-card.component.html',
  styleUrls: ['./migrations-card.component.scss'],
  animations: [
    trigger(
      'animateIn',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('.5s ease-out',
                    style({ opacity: 1 }))
          ]
        ),
      ]
    ),
    trigger(
      'expandIn',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
                    style({ height: 35, opacity: 1 }))
          ]
        ),
      ]
    )
  ]
})
export class MigrationsCardComponent implements OnInit {
  constructor(
    private routeResolverService: RouteResolverService,
    private cardsResourcesService: CardsResourcesService,
    private cardsUtilities: CardsUtilities,
    private abstractUserProvider: AbstractUserProvider,
    private notificationsControlService: NotificationsControlService
  ) { }

  public migrationsSaved: string;
  public migrationsInProgress: string;
  public gettingCounts = true;
  public gettingAlerts = true;
  public formattedCritialAlertCount = '0';
  public showBlurb = true;

  readonly migrationsFullAccessPermission = 'Migrations_Full_Access';

  ngOnInit(): void {
    this.getSummary();
    this.getCriticalAlerts();
  }

  getEnterpriseUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.Migrate, '/partner/enterprise/new');
  }

  getSMBUrl(): string{
    return this.routeResolverService.generateRatRoute(AppId.Migrate, '/partner/migrations/smb');

  }

  getDataOnlyUrl(): string{
    return this.routeResolverService.generateRatRoute(AppId.Migrate, '/partner/dataonly/new');
  }

  getSummary(): void{
    if (this.canAccess()) {
        this.cardsResourcesService.getMigrationSummary().subscribe(
          x => {
            this.migrationsInProgress = this.cardsUtilities.abbreviateCount(x.inProgress);
            this.migrationsSaved =  this.cardsUtilities.abbreviateCount(x.saved);
            this.gettingCounts = false;
            this.showBlurb = (this.migrationsSaved === '0' && this.migrationsInProgress === '0');
          },
          err => {
            this.gettingCounts = false;
          }
        );
    } else {
        this.migrationsInProgress = '0';
        this.migrationsSaved = '0';
        this.gettingCounts = false;
        this.showBlurb = true;
    }
  }

  getCriticalAlerts(): void {
    if (this.canAccess()) {
        this.cardsResourcesService.getAlertStatistics().subscribe(
          x => {
            this.formattedCritialAlertCount = this.cardsUtilities.abbreviateCount(x.migrationStatistics.totalCriticalAlerts);
            this.gettingAlerts = false;
          },
          err => {
            this.gettingAlerts = false;
          }
        );
    } else {
        this.formattedCritialAlertCount = '0';
        this.gettingAlerts = false;
    }
  }

  onMigrationsInProgressClick(): void {
    window.location.href = this.routeResolverService.generateRatRoute('migrate', 'partner/migrations/monitor');
  }

  onSavedMigrationsClick(): void {
    window.location.href = this.routeResolverService.generateRatRoute('migrate', 'partner/migrations-pending');
  }

  onCritAlertsClick(): void {
    this.notificationsControlService.setModalFilter(true, ProductTypes.Migration, SeverityTypes.Critical);
  }

  goToMigrations(): void {
    window.location.href = this.routeResolverService.generateRatRoute(AppId.VNextMigrate, 'dashboard');
  }

  canAccess(): boolean {
    return this.abstractUserProvider.getCurrentUser().hasPermission(this.migrationsFullAccessPermission);
  }
}
