import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { AppInsightsMonitorService } from './services/app-insights-monitor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private userProvider: AbstractUserProvider,
    private cookieService: CookieService,
    private appInsights: AppInsightsMonitorService
  ) {}

  private cookieNameAccessToken: string = 'sk_access_token';
  private cookieNameIdToken: string = 'sk_id_token';

  run() {
    return new Promise<void>((resolve, reject) => {
      ///
      /// WARNING: This is intentionally undeveloped.  Entire
      ///          login page redirection and JWT validation
      ///          system needs a real design.
      ///          CM #76699 / PS #70222
      ///
      const portalPageThatWillRedirectToLoginPage = this.getLoginRedirect();
      try {
        if (!this.isCompleteProfilePage()) {
          this.userProvider.getCurrentUser();
        }
        resolve();
      } catch (e) {
        // The currently logged in user doesn't exist or their session has expired
        window.location.href = portalPageThatWillRedirectToLoginPage;
        reject(new Error('User is not logged into the portal'));
      }
    });
  }

  apiAuthError() {
    // Sometimes non-admin users get a 401, but their session is still valid.
    if (this.hasAuthCookies()) return; 

    // The intention here is to do the same as what run() does when it detects a user is not logged in. 
    // If run() is changed to do logout instead, this should also be changed to do logout.
    this.removeAuthCookies();
    this.appInsights.clearAuthenticatedUserContext();
    window.location.href = this.getLoginRedirect();
  }

  public logout(): boolean {
    this.removeAuthCookies();
    this.appInsights.clearAuthenticatedUserContext();

    const currentOrigin = window.location.origin.toLocaleLowerCase();
    const encodedCurrentOrigin = encodeURIComponent(currentOrigin);

    window.location.href = `${this.getLogoutUrlForEnv(
      currentOrigin
    )}?return_to=${encodedCurrentOrigin}`;

    return false;
  }

  public isCustomerUser(): boolean {
    return this.userProvider.getCurrentUser().isCustomer;
  }

  public hasRole(role: string): boolean {
    return this.userProvider.getCurrentUser().hasPermission(role);
  }

  private hasAuthCookies() {
    return this.cookieService.check(this.cookieNameAccessToken) && 
      this.cookieService.check(this.cookieNameIdToken);
  }

  private removeAuthCookies(): void {
    this.cookieService.delete(
      this.cookieNameAccessToken,
      '/',
      '.skykick.com',
      undefined,
      'Lax'
    );
    this.cookieService.delete(
      this.cookieNameIdToken,
      '/',
      '.skykick.com',
      undefined,
      'Lax'
    );
  }

  private isCompleteProfilePage(): boolean {
    const currentPath = window.location.pathname.toLocaleLowerCase();
    return currentPath === '/complete';
  }

  private getLoginRedirect(): string {
    const fullUrl = new URL(window.location.href);
    const baseUrl = (fullUrl.origin + fullUrl.pathname).toLocaleLowerCase();
    const returnTo = fullUrl.searchParams.get('return_to') ?? baseUrl;
    const encodedReturnTo = encodeURIComponent(returnTo);
    let loginRedirectUrl = this.getLoginUrlForEnv(baseUrl);

    return `${loginRedirectUrl}?return_to=${encodedReturnTo}`;
  }

  private getLoginUrlForEnv(url): string {
    if (url.includes('localhost')) {
      return "http://localhost:15200/login";
    } else if (url.includes('dev0')) {
      return 'https://sk-dev0-auth.skykick.com/login';
    } else if (url.includes('-dev1')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev2')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev3')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev4')) {
      return 'https://sk-dev4-auth.skykick.com/login';
    } else if (url.includes('-dev5')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev6')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev7')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev8')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-dev9')) {
      return 'https://sk-dev-auth.skykick.com/login';
    } else if (url.includes('-qa2')) {
      return 'https://sk-qa2-auth.skykick.com/login';
    } else if (url.includes('-qa')) {
      return 'https://sk-qa-auth.skykick.com/login';
    } else if (url.includes('-int')) {
      return 'https://sk-int-auth.skykick.com/login';
    } else if (url.includes('canary')) {
      return 'https://auth.canary.skykick.com/login';
    } else {
      return 'https://auth.skykick.com/login';
    }
  }

  private getLogoutUrlForEnv(url): string {
    if (url.includes('localhost')) {
      // Login Provider listens on port 4000 locally (SkyKick.Platform.LoginProvider within the SKyKick.Platform.Identity repo).
      return "http://localhost:4000/logout";
    } else if (url.includes('dev0')) {
      return 'https://sk-dev0-auth.skykick.com/logout';
    } else if (url.includes('-dev1')) {
      return 'https://sk-dev1-auth.skykick.com/logout';
    } else if (url.includes('-dev2')) {
      return 'https://sk-dev2-auth.skykick.com/logout';
    } else if (url.includes('-dev3')) {
      return 'https://sk-dev3-auth.skykick.com/logout';
    } else if (url.includes('-dev4')) {
      return 'https://sk-dev4-auth.skykick.com/logout';
    } else if (url.includes('-dev5')) {
      return 'https://sk-dev5-auth.skykick.com/logout';
    } else if (url.includes('-dev6')) {
      return 'https://sk-dev6-auth.skykick.com/logout';
    } else if (url.includes('-dev7')) {
      return 'https://sk-dev7-auth.skykick.com/logout';
    } else if (url.includes('-dev8')) {
      return 'https://sk-dev8-auth.skykick.com/logout';
    } else if (url.includes('-dev9')) {
      return 'https://sk-dev9-auth.skykick.com/logout';
    } else if (url.includes('-qa2')) {
      return 'https://sk-qa2-auth.skykick.com/logout';
    } else if (url.includes('-qa')) {
      return 'https://sk-qa-auth.skykick.com/logout';
    } else if (url.includes('-int')) {
      return 'https://sk-int-auth.skykick.com/logout';
    } else if (url.includes('canary')) {
      return 'https://auth.canary.skykick.com/logout';
    } else {
      return 'https://auth.skykick.com/logout';
    }
  }
}
