import {
  Component,
  OnInit,
} from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  switchMap,
  tap,
} from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { MembersService } from '../services/members.service';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'sk-remove-member-modal',
  templateUrl: './remove-member-modal.component.html',
})
export class RemoveMemberModalComponent implements OnInit {

  memberId: string;
  isDeleteRunning = false;
  memberRemoved = false;

  constructor(
    private modal: NgbActiveModal,
    private membersService: MembersService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private errorFactory: ErrorFactory) { }

  ngOnInit(): void {
  }

  remove(): void {
    if (this.isDeleteRunning) {
      return;
    }

    this.isDeleteRunning = true;

    this.membersService.deleteMember(this.memberId).pipe(
      switchMap(result => {
        if (result){
          this.memberRemoved = true;
          return this.translateService.get('settings.members.remove-member.remove-success').pipe(
            tap((successText: string) => this.toastrService.success(successText))
          );
        }
      }),
      catchError((response: HttpErrorResponse) => this.errorFactory.getMessage(response?.error).pipe(
        tap((errorText: string) => this.toastrService.error(errorText))
      ))
    ).subscribe(_ => {
      this.isDeleteRunning = false;
      this.modal.close(this.memberRemoved);
    });
  }

  dismiss(): void {
      this.modal.dismiss();
  }

  close(): void {
      this.modal.close();
  }
}
