import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import {
  AvatarModule,
  LayoutModule,
  ModalsModule,
  TasksModule,
} from '@skykick/core';

import { SharedModule } from '../shared/shared.module';
import {
  AddCustomerComponent,
} from './components/customers/add-customer/add-customer.component';
import {
  CustomerDetailsPanelComponent,
} from './components/customers/customer-details-panel/customer-details-panel.component';
import { CustomersComponent } from './components/customers/customers.component';
import {
  EditCustomerComponent,
} from './components/customers/edit-customer/edit-customer.component';
import {
  RemoveCustomerModalComponent,
} from './components/customers/remove-customer-modal/remove-customer-modal.component';
import {
  AddEditMemberFormComponent,
} from './components/members/add-edit-member-form/add-edit-member-form.component';
import {
  MemberDetailsPanelComponent,
} from './components/members/member-details-panel/member-details-panel.component';
import { MembersComponent } from './components/members/members.component';
import {
  RemoveMemberModalComponent,
} from './components/members/remove-member-modal/remove-member-modal.component';
import { ResetMfaModalComponent } from './components/members/reset-mfa-modal/reset-mfa-modal.component';
import { UsersComponent } from './components/users.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  declarations: [
    UsersComponent,
    MembersComponent,
    CustomersComponent,
    AddEditMemberFormComponent,
    RemoveMemberModalComponent,
    RemoveCustomerModalComponent,
    AddCustomerComponent,
    EditCustomerComponent,
    MemberDetailsPanelComponent,
    CustomerDetailsPanelComponent,
    ResetMfaModalComponent
  ],
  imports: [
    LayoutModule,
    AvatarModule,
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    TasksModule,
    FormsModule,
    ReactiveFormsModule,
    ModalsModule,
  ]
})
export class UsersModule { }