<div class="sk-content-column">
    <div class="flex-center-center pb-100 d-none">
        <div class="sk-spin-loader"></div>
    </div>

    <div class="sk-content-primary">
        <div class="sk-flex-table">
            <div id="scrollWrap" class="sk-scroll-container">
                <div class="d-flex flex-column min-width-lg max-width-lg pt-150">
                    <div class="row">
                        <div class="col-10">
                            <p>Add the tools and apps to optimize your team's productivity with SkyKick.</p>
                            <div class="card card-hover mt-100" [routerLink]="['/', 'settings', 'integrations', 'connectwise', 'details']">
                                <div class="card-block">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="d-flex mr-100 rounded align-items-start justify-content-center square-56">
                                            <img src="/assets/svg/connectwise-icon.svg" class="img-fluid d-flex">
                                        </div>
                                        <div class="d-flex flex-column">
                                            <h4><a href [routerLink]="['/', 'settings', 'integrations', 'connectwise', 'details']">Connectwise Manage</a></h4>
                                            <p class="mt-25 text-muted">Billing and Sales</p>
                                            <p>Map products, tickets and companies between SkyKick and Connectwise to manage your invoices.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card card-hover mt-100" >
                                <div class="card-block">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="d-flex mr-100 rounded align-items-start justify-content-center square-56">
                                            <img src="/assets/svg/icon-microsoft.svg" class="img-fluid d-flex">
                                        </div>
                                        <div class="d-flex flex-column">
                                            <h4><a [href]="getMicrosoftPartnerCenterRoute()">Microsoft Partner Center</a></h4>
                                            <p class="mt-25 text-muted">IT and Productivity</p>
                                            <p>Connect to Microsoft Partner Center to sync your teammates, customers, and manage tenants with commands.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="sk-page-footer">
        <div class="sk-footer-content">
            <button type="button" class="btn btn-lg btn-primary mr-100">Send Invite</button>
            <button type="button" class="btn btn-lg btn-outline mr-150">Cancel</button>
        </div>
    </div> -->
</div>
