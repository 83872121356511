import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { ITracingEnvironment } from "../models/ITracingEnvironment";
import { BuildInfo } from "../config/version.config";
import { Injectable } from '@angular/core';
import { PortalTracingModule } from '../portal.tracing.module';
import { ITracingConfiguration } from '../models/ITracingConfiguration';

@Injectable({
    providedIn: 'root',
})
export class PortalTracingService {

    private isInitialized = false;

    constructor() {
        'ngInject';
    }

    /**
     * Bootstraps the DataDog RUM client
     */
    initializePortalTracing(environment: ITracingConfiguration): void {        
        // Builds configuration
        var configuration: ITracingConfiguration;
        if (environment != null) {

            const sessionSampleRate = environment.sessionSampleRate ?? 0;
            const sessionReplaySampleRate = environment.sessionReplaySampleRate ?? 0;
            const privacyType = this.getPrivacyType(environment.defaultPrivacyView);

            configuration = {
                applicationId: environment.applicationId,
                clientToken: environment.clientToken,
                defaultPrivacyView: privacyType,
                environment: environment.environment,
                // Only collect data if we have a configured sample rate.
                enabled: (sessionSampleRate + sessionReplaySampleRate) > 0,
                serviceName: environment.serviceName,
                version: BuildInfo.get().version,
                sessionReplaySampleRate: sessionReplaySampleRate,
                sessionSampleRate: sessionSampleRate,
                // the environment values can come across as string - if they are truthy, make them explicit
                apmTracingEnabled: Boolean(environment.apmTracingEnabled),
            }
            this.initialize(configuration);
        }        
    }

    /**
     * Initializes the DataDog RUM client
     * @param config Configuration data
     */
    private initialize(config: ITracingConfiguration): void {
        if (this.isInitialized == false && this.isEnabledAndValid(config)) {
            datadogRum.init({
                applicationId: config.applicationId,
                clientToken: config.clientToken,
                site: 'datadoghq.com',
                service: config.serviceName,
                sessionSampleRate: config.sessionSampleRate,
                sessionReplaySampleRate: config.sessionReplaySampleRate,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: <DefaultPrivacyLevel>config.defaultPrivacyView,
                // General configuration data
                env: config.environment,
                version: config.version,
                // APM Tracing
                allowedTracingOrigins: config.apmTracingEnabled ? [window.location.origin] : undefined,
                beforeSend: (event) => {
                    event.view.url = event.view.url.replace('#', '');
                }
            });
            // If we are configured for a sample of replay events, then enable
            if (config.sessionReplaySampleRate > 0) {
                // Start recording for session replay
                datadogRum.startSessionReplayRecording();
            }
            this.isInitialized = true;
        } else if (this.isInitialized) {
            console.error("TracingService initialized multiple times");
        }
    }

    /**
     * sets the user context for the current session
     * interacting
     * @param func Function to call if successfully configured 
     */
    public setUserData(user): void {
        this.ensureInitialized(() => {
            datadogRum.setUser({
                email: user.crmCurrentUser.auditUsername
            });
            datadogRum.setUserProperty('isImpersonatedUser', user.crmCurrentUser.isImpersonatedUser.toString());
            datadogRum.setUserProperty('isPartner', user.crmCurrentUser.isPartner.toString());
            if (user.crmCurrentUser.isImpersonatedUser) {
                datadogRum.setUserProperty('impersonationUser', user.crmCurrentUser.username);
            }
            datadogRum.setUserProperty("partnerId", user.crmCurrentUser.partnerId);
        });
    }

    /**
     * Wrapper function to help ensure the tracing client is configured before
     * interacting
     * @param func Function to call if successfully configured 
     */
    private ensureInitialized(func: any): void {
        if (this.isInitialized) {
            func();
        }
    }

    /**
     * Returns a boolean indicating if the Tracing configuration is enabled and has the bare
     * minimum configuration (applicationId and clientToken)
     * @param config DataDog Tracing configuration
     * @returns True if enabled and configured, otherwise false
     */
    private isEnabledAndValid(config: ITracingConfiguration): boolean {
        if (config?.enabled) {
            // Make sure we at least have an applicationId and a clientToken
            return config.applicationId?.length > 0 && config.clientToken?.length > 0;
        }
        return false;
    }

    /**
     * 
     * @param defaultPrivacyView Configuration input
     * @returns 
     */
    private getPrivacyType(defaultPrivacyView: string): DefaultPrivacyLevel {
         
        const dataDogPrivacyViewTypes: string[] = [
            DefaultPrivacyLevel.ALLOW,
            DefaultPrivacyLevel.MASK,
            DefaultPrivacyLevel.MASK_USER_INPUT
        ];
        if (defaultPrivacyView?.length > 0) {
            if (dataDogPrivacyViewTypes.find(x => x == defaultPrivacyView)) {
                return <DefaultPrivacyLevel>defaultPrivacyView;
            }
        }
        // All else fails, mask everything
        return DefaultPrivacyLevel.MASK;
    }

}
