import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationType } from '../models/authentication-type';
import { Observable, forkJoin } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { Ms365AuthenticationComponent } from './modals/ms365-authentication/ms365-authentication.component';
import { AuthenticationMethodResourcesService } from '../services/authentication-method-resources.service';
import { PartnerAuthentication } from '../models/partner-authentication';
import { SkyKickModal, SkyKickModalOptions, SkyKickModalService, SkyKickModalWarningOptions, WarningModalComponent } from '@skykick/core';
import { M365ConnectionStatuses } from '../constants/m365ConnectionStatus.constant';

@Component({
    selector: 'sk-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
    private overlayConfig: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-panel modal-right', size: 'lg' };
    AuthenticationType = AuthenticationType;
    M365ConnectionStatuses = M365ConnectionStatuses;
    debugEnabled = false;
    isLoading: boolean;
    authSettingsForm: FormGroup;
    isBusy: boolean;
    partnerAuthentication: PartnerAuthentication;
    groupSyncStatus: any;
    m365AuthConnectionStatus = null;
    
    private optionsError: SkyKickModalOptions = {
        title: this.translate.instant('ERROR'),
        body: this.translate.instant('COMMON.PLEASE_TRY_AGAIN_OR_CONTACT'),
        btnLabel: this.translate.instant('COMMON.CLOSE')
    };

    private optionsGroupSyncError: SkyKickModalOptions = {
        title: this.translate.instant('ERROR'),
        body: this.translate.instant('settings.O365.ENABLE_GROUP_SYNC_ERROR'),
        btnLabel: this.translate.instant('COMMON.CLOSE')
    };

    private debug(...args: any[]) {
        if (!this.debugEnabled) return;
        console.debug("AuthenticationComponent", arguments);
    }

    // Switches the user over from M365 Auth to SkyKick Auth.
    private async savePartnerAuthenticationSettings() {
        const self = this;
        self.debug("savePartnerAuthenticationSettings");
        const payload = {
            AuthenticationType: self.authSettingsForm.get('authenticationType').value,
            IsMFAEnabled: self.authSettingsForm.get('isMFAEnabled').value,
            UsersToMap: self.partnerAuthentication.unMappedUsers
        };

        this.isBusy = true;
        try {
            self.debug(payload);
            await this.authenticationMethodResourcesService.savePartnerAuthenticationSettings(payload);
            if (this.partnerAuthentication.authenticationType === AuthenticationType.O365Auth) {
                this.authenticationMethodResourcesService.disableM365GroupSync().pipe(first()).subscribe(
                    () => {
                        this.updateUser();
                    },
                    () => {
                        this.showGenericErrorModal();
                    }
                );
            }
        } catch (error) {
            self.debug(error);
            this.isBusy = false;
            this.toastrService.error(this.translate.instant('Error Changing O365 Login for Partner'));
        } finally {
            this.isBusy = false;
        }
    }

    private updateUser(): void {
        // If we actually updated the value, show success.
        if (this.authSettingsForm.get('authenticationType').value !== this.partnerAuthentication.authenticationType) {
            this.toastrService.success(this.translate.instant('settings.O365.SKYKICK_AUTH_SUCCESS'));
        }
        if (this.authSettingsForm.get('isMFAEnabled').value) {
            this.toastrService.success(this.translate.instant('settings.O365.MFA_SETTING_SUCCESS'));
        }
        if (!this.authSettingsForm.get('isMFAEnabled').value && this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType) {
            this.toastrService.success(this.translate.instant('settings.O365.MFA_SETTING_DISABLED'));
        }
        this.partnerAuthentication.authenticationType = this.authSettingsForm.get('authenticationType').value;
        this.partnerAuthentication.isMFAEnabled = this.authSettingsForm.get('isMFAEnabled').value;

        // Uncheck the box for group sync enabled.
        this.groupSyncStatus.groupsSyncEnabled = false;
        this.patchGroupSyncOnForm();
        this.authSettingsForm.markAsPristine();
    }

    constructor(
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private modalService: NgbModal,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private skyKickModalService: SkyKickModalService
    ) { }

    ngOnInit(): void {
        this.debug("ngOnInit");
        this.authSettingsForm = this.formBuilder.group({
            authenticationType: [null, [Validators.required]], // SkyKick Authentication or Microsoft 365 Authentication
            isMFAEnabled: [null], // checkbox for enabling multi-factor authentication
            groupsSyncEnabled: [null] // checkbox for enabling Microsoft 365 group sync 
        });
        this.authSettingsForm.get('authenticationType').valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(value => {
            this.refreshUserChoices(value);
        });
        this.formReady();
    }

    private formReady() {
        this.isLoading = true;
        forkJoin({
            partnerAuthSettings: this.authenticationMethodResourcesService.getPartnerAuthenticationSettings(),
            groupSyncStatus: this.authenticationMethodResourcesService.getGroupSyncStatus(),
            m365AuthConnectionStatus: this.authenticationMethodResourcesService.getM365AuthConnectionStatus()
        }).subscribe(res => {
            this.partnerAuthentication = res.partnerAuthSettings;
            this.partnerAuthentication.oAuthFlowState = this.partnerAuthentication.oAuthFlowState ? JSON.parse(this.partnerAuthentication.oAuthFlowState) : null;
            this.m365AuthConnectionStatus = res.m365AuthConnectionStatus;
            
            // Set the form values to what they currently are.
            this.authSettingsForm.patchValue(res.partnerAuthSettings);
            if (this.hasOngoingAuthFlow()) {
                this.debug('ongoing auth flow');
                this.groupSyncStatus = {groupsSyncEnabled: this.partnerAuthentication.oAuthFlowState.userWantedGroupSync};
            } else {
                this.debug('no ongoing auth flow');
                this.groupSyncStatus = res.groupSyncStatus;
            }
            this.patchGroupSyncOnForm();
            
            // It doesn't make sense to have group sync as a form option if we aren't using O365 Auth.
            if (!this.hasOngoingAuthFlow() && this.partnerAuthentication.authenticationType !== AuthenticationType.O365Auth) {
                this.authSettingsForm.get("groupsSyncEnabled").setValue(false);
                this.authSettingsForm.get('groupsSyncEnabled').disable();
            }
            this.isLoading = false;
            
            // Note: This was copied from portal v1 AdminManageAccountController.init() to fix an issue where this was missing.
            // If there is #setupo365authentication at the end of the URL, it is likely we need to take the user to step 3 of the O365Auth setup process.
            const hash = window.location.hash; // e.g. #setupo365authentication
            if (hash && hash.length > 1) {
                const sectionId = hash.substring(1); 
                this.locationHashFound(sectionId);
            }
        });
    }

    private refreshUserChoices(value: AuthenticationType) {
        // MFA only makes sense for SkyKick Authentication.
        if (value === AuthenticationType.O365Auth) {
            this.authSettingsForm.get('isMFAEnabled').setValue(false);
            this.authSettingsForm.get('isMFAEnabled').disable();
        }
        if (value === AuthenticationType.SkyKickAuth) {
            this.authSettingsForm.get('isMFAEnabled').enable();
        }
        // Group sync only makes sense for Microsoft 365 Authentication.
        if (value === AuthenticationType.O365Auth) {
            this.authSettingsForm.get('groupsSyncEnabled').enable();
        }
        if (value === AuthenticationType.SkyKickAuth) {
            this.authSettingsForm.get('groupsSyncEnabled').setValue(false);
            this.authSettingsForm.get('groupsSyncEnabled').disable();
        }
    }

    private locationHashFound(elementId: string): void {
        const self = this;
        self.debug("Jumping to section " + elementId);
        const element = document.getElementById(elementId);
        if (elementId === 'setupo365authentication') {
            // We came back from AccessManagement success page.
            // This window.setTimeout code was taken from Portal V1.
            window.setTimeout(function () {
                self.debug("scrollIntoView timeout");
                self.authSettingsForm.get('authenticationType').patchValue(AuthenticationType.O365Auth);
                element.scrollIntoView();
            }, 2000);
            window.setTimeout(function () {
                self.debug("openAuthFlowModal timeout");
                self.openAuthFlowModal();
            }, 3000);
        }
    }

    private updateAuthentication(): void {
        this.debug("updateAuthentication");
        if (this.authSettingsForm.valid && !this.authSettingsForm.pristine) {
            if (this.authSettingsForm.get('authenticationType').value === AuthenticationType.SkyKickAuth) {
                if (this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType &&
                    this.authSettingsForm.get('isMFAEnabled').value === this.partnerAuthentication.isMFAEnabled) {
                    this.authSettingsForm.markAsPristine();
                    return;
                }
                this.userChoseSkyKickAuth();
            }
            else if (this.authSettingsForm.get('authenticationType').value === AuthenticationType.O365Auth) {
                if (this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType &&
                     this.authSettingsForm.get('groupsSyncEnabled').value === this.groupSyncStatus.groupsSyncEnabled) {
                     this.authSettingsForm.markAsPristine();
                     return;
                }
                this.userChoseM365Auth();  
            }
        }
    }

    private userChoseSkyKickAuth() {
        if (this.partnerAuthentication.authenticationType === AuthenticationType.O365Auth) {
            let warningModal: SkyKickModal<WarningModalComponent, void>;
            const options: SkyKickModalWarningOptions = {
                body: this.translate.instant('settings.O365.DISABLE_WARNING_HEADER'),
                title: this.translate.instant('settings.O365.DISABLE_MODAL_TITLE'),
                btnLabel: this.translate.instant('settings.O365.DISABLE_CONFIRM_BUTTON_TEXT'),
                verifyLabel: this.translate.instant('settings.O365.DISABLE_CONFIRM_TEXT'),
                alternative: {
                    btnLabel: this.translate.instant('settings.account.DISTRIBUTOR_CANCEL'),
                    btnCallback: () => {
                        warningModal.dismiss();
                        this.cancel();
                    }
                }
            };
            warningModal = this.skyKickModalService.warning(options);
            warningModal.result.then(res => {
                if (res.wasClosed) {
                    this.savePartnerAuthenticationSettings();
                }
            });
        } else {
            this.savePartnerAuthenticationSettings();
        }                
    }

    private userChoseM365Auth(): void {
        if (!this.authSettingsForm.get('groupsSyncEnabled').value && this.groupSyncStatus.groupsSyncEnabled) {
            let warningModal: SkyKickModal<WarningModalComponent, void>;
            const options: SkyKickModalWarningOptions = {          
                body: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_WARNING'),
                title: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_MODAL_TITLE'),
                btnLabel: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_CONFIRM_BUTTON_TEXT'),
                verifyLabel: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_CONFIRM_TEXT'),
                alternative: {
                    btnLabel: this.translate.instant('settings.account.DISTRIBUTOR_CANCEL'),
                    btnCallback: () => {
                        warningModal.dismiss();
                        this.cancel();
                    }
                }
            };
            warningModal = this.skyKickModalService.warning(options);
            warningModal.result.then(res => {
                if (res.wasClosed) {
                    // They accepted the warning... let's go ahead and turn off group sync.
                    this.authenticationMethodResourcesService.disableM365GroupSync().pipe(first()).subscribe(
                        () => {
                            this.groupSyncStatus.groupsSyncEnabled = this.authSettingsForm.get('groupsSyncEnabled').value;
                            this.authSettingsForm.markAsPristine();
                            this.toastrService.success(this.translate.instant('COMMON.SAVED_SUCCESSFULLY')); 
                        },
                        () => {
                            this.showGenericErrorModal();
                        }
                    );
                }
            });
        } else {
            this.openAuthFlowModal();
        }    
    }

    private openAuthFlowModal(): void {
        this.debug("openAuthFlowModal");
        const modalRef = this.modalService.open(Ms365AuthenticationComponent, this.overlayConfig);
        modalRef.componentInstance.partnerAuthentication = this.partnerAuthentication;
        modalRef.componentInstance.groupsSyncEnabled = this.authSettingsForm.get('groupsSyncEnabled').value;
        modalRef.componentInstance.m365AuthConnectionStatus = this.m365AuthConnectionStatus;
        modalRef.result.then(result => {
            switch (result.status) {
                case 'failure':
                    this.toastrService.error(this.translate.instant('settings.O365.ENABLING_ERROR'));
                    break;
                case 'warning':
                    this.partnerAuthentication.authenticationType = AuthenticationType.O365Auth;
                    this.partnerAuthentication.isMFAEnabled = false;
                    this.authSettingsForm.markAsPristine();
                    break;
                case 'success':
                    this.partnerAuthentication.authenticationType = AuthenticationType.O365Auth;
                    this.partnerAuthentication.isMFAEnabled = false;
                    if (!result.adGroupSyncEnabled) {
                        // Delay the toast message until the group sync part finishes.
                        this.toastrService.success(this.translate.instant('settings.O365.ENABLING_SUCCESS'));
                        this.authSettingsForm.markAsPristine();
                    }
                    break;
                case 'redirect':
                    this.authSettingsForm.markAsPristine();
                    window.location.href = result.url;
                    break;
            }
            return {
                m365AuthEnabled: result.status === 'success',
                adGroupSyncEnabled: result.adGroupSyncEnabled 
            }
        }).then(result => {
            if (result.m365AuthEnabled && result.adGroupSyncEnabled) {
                this.syncGroups().pipe(first()).subscribe(
                    () => {
                        this.toastrService.success(this.translate.instant('settings.O365.ENABLING_SUCCESS'));
                        this.authSettingsForm.markAsPristine();
                        this.clearOngoingAuthFlow();
                    },
                    () => {
                        this.showGroupSyncFailedErrorModal();
                        location.reload();
                    }
                );
            }
        }).catch(() => {
            // The user hit the X or the cancel button.
            this.cancel();
        });
    }

    private patchGroupSyncOnForm(): void {
        this.authSettingsForm.patchValue(this.groupSyncStatus); 
    }

    private showGenericErrorModal(): void {
        this.skyKickModalService.error(this.optionsError);
    }

    private showGroupSyncFailedErrorModal(): void {
        this.skyKickModalService.error(this.optionsGroupSyncError);
    }

    private syncGroups(): Observable<any> {
        return this.authenticationMethodResourcesService.beginM365GroupSync();
    } 

    hasOngoingAuthFlow(): boolean {
        let result = false;
        if (this.partnerAuthentication?.oAuthFlowState && typeof this.partnerAuthentication.oAuthFlowState !== 'string') {
            result = !!this.partnerAuthentication.oAuthFlowState.authorizationStarted;
        }
        return result;
    }

    private clearOngoingAuthFlow() {
        const payload =  JSON.stringify({
            authorizationStarted: false,
        });
        if (this.partnerAuthentication?.oAuthFlowState) {
            this.partnerAuthentication.oAuthFlowState = null;
        }
        this.authenticationMethodResourcesService.saveOAuthFlowState({State: payload}).pipe(first()).subscribe(
            () => {

            },
            () => {
                this.showGenericErrorModal();
            }
        );
    }

    isDisabled(): boolean {
        if (this.authSettingsForm.invalid || this.isBusy || this.authSettingsForm.pristine || (this.partnerAuthentication.authenticationType === AuthenticationType.O365Auth && this.m365AuthConnectionStatus === M365ConnectionStatuses.reauthenticationRequired)) {
            return true;
        }
        return false;
    }

    private cancel(): void {
        this.authSettingsForm.patchValue(this.partnerAuthentication);
        this.authSettingsForm.patchValue(this.groupSyncStatus);
        this.authSettingsForm.markAsPristine();
    }
}
