import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  AbstractUserProvider,
  ISkyKickPartnerPortalUser,
  ISkyKickPartnerPortalUserImpersonationDetailsProvider }
from '@skykick/platform-identity-auth-auth0-angular';

import { Utils } from '../../services/utils';
import { PapiHttpService } from './papi-http.service';

import { SkyKickModalService } from '@skykick/core';
import { Observable } from 'rxjs';
import { Action } from '../models/Action';


// This can be used for debugging...
// declare global {
//   interface Window { MpnCheck: any; }
// }
// window.MpnCheck = window.MpnCheck || {};

// This service does the following:
//   1. Checks if the user needs to enter an MPN according to session storage and/or an API call.
//      If they don't, it brings them to the nextp step.
//   2. Checks if the user needs to enter an address along with their MPN data.
//   3. Checks if the user needs to accept terms and conditions.
//   4. Shows a partner distribution id.
//   5. (If anything goes wrong) shows a genericerror dialog.
// Depending on the situation, the user will be routed to the appropriate destination.
@Injectable({
  providedIn: 'root'
})
export class MpnCheckService {
  serviceName = 'MpnCheckService'; // even if the class name is minified, this will work.
  portalUrl = window.location.origin;
  accountSetupPath = '/accountsetup';
  userProfilePath = '/partner/admin/user-profile';
  sessionStorageKeyMpn = 'MpnRequired';
  sessionStorageKeyAddress = 'AddressRequired';

  // These are loaded from a PAPI call only if the MPN is already found.
  distributorId = '';
  affiliation = '';
  userId = '';

  // PortalV1 uses sessionStorage, localStorage, and location...
  // so the same is done here.
  windowSessionStorage: Storage;
  windowLocalStorage: Storage;
  windowLocation: Location;

  // Used for impersonation.
  user: ISkyKickPartnerPortalUser;

  constructor(
    private translate: TranslateService,
    private skModalService: SkyKickModalService,
    private papiRoute: PapiHttpService,
    private userProvider: AbstractUserProvider,
    private impersonationProvider: ISkyKickPartnerPortalUserImpersonationDetailsProvider
  ) {
    this.debug("constructor", "enter");
    this.windowSessionStorage = window.sessionStorage;
    this.windowLocalStorage = window.localStorage;
    this.windowLocation = window.location;

    this.user = this.userProvider.getCurrentUser();
    //window.MpnCheck = this;
  }

  // Called from the homepage if we need to have the user enter their MPN.
  homepageCheck() {
    const self = this;
    self.debug("homepageCheck", "enter");
    const sessionMpnRequired = self.getMpnRequiredFromSession();
    self.performCheck(sessionMpnRequired, false).subscribe(action => {
      // Perform check sets some session variables (mpnRequired, sessionRequired).
      if (action !== Action.GoToHomePage) {
        self.run(action);
      }
    });
  }

  // Run the workflow logic. This runs on multiple pages.
  run(action: Action): void {
    this.debug("run", Action[action]);
    // Note: This logic was ported over from Portal V1 (AngularJS)
    // and then refactored for Portal V2 (new Angular).
    // Also, what action we should do next was decoupled from actually performing it
    // for easier testing and readability.
    switch (action) {
      case Action.GoToAccountSetupPage:
        this.goToAccountSetupPage();
        break;
      case Action.GoToHomePage:
        this.goToHomepage();
        break;
      case Action.ShowPartnerDistributionId:
        this.windowLocalStorage.localStorage.setItemsetItem("user.distributorId", this.distributorId);
        this.windowLocalStorage.localStorage.setItem("user.id",this.userId);
        this.showDistributorAffiliation(this.affiliation);
        break;
      case Action.ShowGenericErrorDialog:
        this.showGenericErrorModal();
        break;
    }
  }

  // Based on the users current state, check what to do next.
  public performCheck(sessionMpnRequired: boolean | null, locationIsAccountSetupPage: boolean): Observable<Action> {
    const self = this;
    self.debug("performCheck", "sessionMpnRequired: " + sessionMpnRequired + "," + "locationIsAccountSetupPage: " + locationIsAccountSetupPage);
    return new Observable<Action>((subscriber) => {
      if (sessionMpnRequired === null) {
        // This will tell us if mpn is required (and if so, if address is required).
        self.checkPapiRegistrationInfo(locationIsAccountSetupPage).subscribe(result => {
          subscriber.next(result);
          subscriber.complete();
        });
      }
      else if (locationIsAccountSetupPage && sessionMpnRequired) {
        // The user is on the account setup page, and this is exactly where they should be.
        // (true true case)
        subscriber.next(Action.None);
        subscriber.complete();
      }
      else if (locationIsAccountSetupPage && !sessionMpnRequired) {
        // The user is on the account setup page, but they don't need to be... go to the next step.
        // (true false case)
        subscriber.next(Action.GoToHomePage);
        subscriber.complete();
      }
      else if(!locationIsAccountSetupPage && sessionMpnRequired) {
        // The user isn't on the account setup page and should be.
        // (false true case)
        subscriber.next(Action.GoToAccountSetupPage);
        subscriber.complete();
      }
      else if (!self.isImpersonating()) {
        // The user is not on the account setup page, and the session says MPN is not required.
        // (false false case)
        self.checkPapiTermsAndConditions().subscribe(result => {
          subscriber.next(result);
          subscriber.complete();
        });
      }
      else {
        subscriber.next(Action.None);
        subscriber.complete();
      }
    });
  }

  // Check if we need to get an MPN from the user.
  private checkPapiRegistrationInfo(locationIsAccountSetupPage: boolean) : Observable<Action> {
    const self = this;
    self.debug("checkPapiRegistrationInfo", "enter");
    // Let's figure out if they need to put their MPN in or not with an API call.
    return new Observable<Action>((subscriber) => {
      this.papiRoute.getRegistrationInfo().subscribe({
        next(response) {
          if (response.mpnRequired) {
            self.setMpnRequiredInSession(true);
            subscriber.next(Action.GoToAccountSetupPage);
            if (response.addressRequired) {
              self.setAddressRequiredInSession(true);
            } else {
              self.setAddressRequiredInSession(false);
            }
          }
          else {
            self.setMpnRequiredInSession(false);
            self.setAddressRequiredInSession(false);
            locationIsAccountSetupPage ? subscriber.next(Action.GoToHomePage) : subscriber.next(Action.None);
          }
        },
        error(err) {
          self.error(err, "performCheck");
          subscriber.next(Action.ShowGenericErrorDialog);
        },
        complete() {
          self.debug("checkPapiRegistrationInfo", "complete");
          subscriber.complete();
        }
      });
    });
  }

  // Check if the user has accepted the terms and conditions.
  private checkPapiTermsAndConditions(): Observable<Action> {
    const self = this;
    self.debug("checkTermsAndConditions", "enter");
    return new Observable<Action>((subscriber) => {
      self.papiRoute.getTandCAccepted().subscribe({
        next(response) {
          // Note: Portal V1 set these directly here.
          // Decoupling this and setting local storage elsewhere.
          self.distributorId = response.result.DistributorId;
          self.affiliation = response.result.DistributorAffiliation;
          self.userId = response.result.Id;
          if (response.result.PromptPartnerForDistiId) {
            subscriber.next(Action.ShowPartnerDistributionId);
          }
          else if (response.result.ForceTsCsAcceptance) {
            subscriber.next(Action.ShowTermsAndConditionsAcceptance);
          }
        },
        error(err) {
          self.error("checkTermsAndConditions", err);
          subscriber.next(Action.ShowGenericErrorDialog);
        },
        complete() {
          self.debug("checkTermsAndConditions", "complete");
          subscriber.complete();
        }
      });
    });
  }

  // Show a generic error modal to the user.
  public showGenericErrorModal(): void {
    this.debug("showGenericErrorModal", "enter");
    this.skModalService.error({
      title: this.translate.instant('ERROR'),
      body: this.translate.instant('COMMON.PLEASE_TRY_AGAIN_OR_CONTACT'),
      btnLabel: this.translate.instant('COMMON.CLOSE')
    });
  }

  // Shows a modal (informational only) showing distributor affiliation.
  public showDistributorAffiliation(distributorAffiliation: string): void {
    this.debug("showDistributorAffiliation", "enter");
    const title = this.translate.instant('COMMON.MISSING_DISTRIBUTOR_TITLE');
    const body = this.translate.instant('COMMON.MISSING_DISTRIBUTOR_MESSAGE', {
      DistributorAffiliation: distributorAffiliation
    }).toString();
    this.skModalService.success({
      title: title,
      body: body,
      btnLabel: this.translate.instant('COMMON.CLOSE')
    });
  }

  //
  // Location helpers: Code here helps working with the window.location object.
  //

  public onAccountSetupPage(): boolean {
    return this.windowLocation.pathname.indexOf(this.accountSetupPath) !== -1;
  }
  public onHomepage(): boolean {
    return window.location.origin == window.location.href;
  }
  public getAccountSetupPage():string {
    return `${this.portalUrl}${this.accountSetupPath}`;
  }
  public getHomepage() {
    return `${this.portalUrl}/`;
  }
  public goToHomepage() {
    if (this.onHomepage()) return;
    this.setLocation(this.getHomepage());
  }
  public goToAccountSetupPage() {
    if (this.onAccountSetupPage()) return;
    this.setLocation(this.getAccountSetupPage());
  }
  private setLocation(newLocation: any) {
    // Use window.location just like we did in portal v1.
    this.debug("setLocation", "new location " + newLocation)
    this.windowLocation.assign(newLocation);
  }

  //
  // Session helpers: Code here helps working with the window.sessionStorage object.
  //

  // Returns whether or not MPN is required according to session storage.
  // If there isn't anything in session storage, it returns null.
  public getMpnRequiredFromSession(): boolean | null {
    let result: boolean | null;
    var mpnRequired = this.windowSessionStorage.getItem(this.sessionStorageKeyMpn);
    if (mpnRequired) {
      // Note: sessionStorage only accepts strings.
      result = JSON.parse(mpnRequired) === true;
    } else {
      result = null;
    }
    this.debug("getMpnRequiredFromSession returning", result);
    return result;
  }

  // Returns whether or not address is required according to the session.
  public getAddressRequiredFromSession(): boolean | null {
    let result: boolean | null;
    var addressRequired = this.windowSessionStorage.getItem(this.sessionStorageKeyAddress);
    if (addressRequired) {
      result = JSON.parse(addressRequired) === true;
    } else {
      result = null;
    }
    this.debug("getAddressRequiredFromSession returning", result);
    return result;
  }

  // Sets whether or not MPN is required according to session storage.
  public setMpnRequiredInSession(value:  boolean) {
    this.debug("setMpnRequiredInSession", value);
    this.windowSessionStorage.setItem(this.sessionStorageKeyMpn, value.toString());
  }

  // Sets whether or not address is required according to session storage.
  public setAddressRequiredInSession(value:  boolean) {
    this.debug("setAddressRequiredInSession", value);
    this.windowSessionStorage.setItem(this.sessionStorageKeyAddress, value.toString());
  }

  //
  // Logging: Helper methods here for error and debug logging.
  //

  // Log the error to the console.
  private error(methodName: string, err: any) {
    Utils.logError(this.serviceName + "." + methodName, err);
  }

  // Log debugging info to the console.
  private debug(methodName: string, debugInfo: any) {
    Utils.logDebug(this.serviceName + "." + methodName, debugInfo);
  }

  // Impersonation.
  private isImpersonating() {
    return this.impersonationProvider.getImpersonationDetails(this.user).isImpersonation;
  }
}

